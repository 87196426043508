import React, {Component} from "react";

class Footer extends Component {

    render () {


            return (
                <div style={{
                backgroundColor: '#1b262c',
                padding: '10px',
                marginTop: '10px',
                textAlign: 'center',
                        bottom: '0'
                }}>
                    <footer>© evil, inc. 2024</footer>

                </div>

            )
    };
}

export default Footer;
